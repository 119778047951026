<script lang="ts">
export default defineComponent({
  name: 'CustomCheckbox',
  props: {
    value: {
      type: [Boolean, null] as PropType<boolean | null>,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    checked: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue', 'changed'],
  setup(props, { emit }) {
    const selected = ref(props.checked)

    watch(
      () => props.checked,
      (newVal) => {
        selected.value = newVal
      },
    )

    const updateCheckbox = () => {
      if (!props.disabled) {
        selected.value = !selected.value
        emit('update:modelValue', selected.value)
        emit('changed', selected.value)
      }
    }

    return {
      selected,
      updateCheckbox,
    }
  },
})
</script>

<template>
  <div class="flex flex-row text-charcoal body-14-aa">
    <input
      :id="id"
      :checked="checked"
      :disabled="disabled"
      :value="value"
      class="mr-2"
      type="checkbox"
      @change="updateCheckbox($event.target.value)"
    />
    <label class="items-start wrapper body-14-aa block m-0" :for="id" v-html="label" />
  </div>
</template>
